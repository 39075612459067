import React from 'react';
import TextInput from '../TextInput';
import { MaterialIcons, AntDesign, FontAwesome } from '@expo/vector-icons';
import { ViewDescription, ContentIcon, ViewPdf, ViewActionsPdf, IconsPdf } from './style';
import {
    SmallText,
    FooterText,
} from '../../config/theme/globalStyles';
import { useNavigation } from '@react-navigation/native';
import THEME from '../../config/theme';

export function PDFUploader ({ setShowPDFUploadModal, updatedVideo, namePdf, handleTitleChange, deletePdfFromStorage }) {
    const navigation = useNavigation();
    return (
        <>
            <ViewDescription>
                <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
                    (Opcional) Adicione um conteúdo em PDF:
                </SmallText>
            </ViewDescription>
            <ContentIcon onPress={() => setShowPDFUploadModal(true)}>
                <MaterialIcons
                    name="drive-folder-upload"
                    size={24}
                    color={THEME.COLORS.TEXT_ABOUT}
                />
                <FooterText color={THEME.COLORS.TEXT_ABOUT} padding="0.5rem">
                    Upload PDF
                </FooterText>
            </ContentIcon>
            {updatedVideo?.pdf.length > 0 && (
                <>
                    {updatedVideo.pdf.map((item, index) => (
                        <ViewPdf key={index}>
                            <TextInput
                                value={namePdf(item)}
                                placeholder="Digite o nome do pdf"
                                onChangeText={(text) => handleTitleChange(text, index)}
                                width="90%"
                                mode="flat"
                            />
                            <ViewActionsPdf>
                                <IconsPdf
                                    onPress={() => {
                                        navigation.navigate('PDF', {
                                            pdf: item.url,
                                        });
                                    }}
                                    style={{ paddingRight: '1.5rem' }}
                                >
                                    <AntDesign
                                        name="pdffile1"
                                        size={28}
                                        color={THEME.COLORS.TEXT_ABOUT}
                                    />
                                </IconsPdf>
                                <IconsPdf onPress={() => deletePdfFromStorage(item.url)}>
                                    <FontAwesome
                                        name="trash-o"
                                        size={28}
                                        color={THEME.COLORS.TEXT_ABOUT}
                                    />
                                </IconsPdf>
                            </ViewActionsPdf>
                        </ViewPdf>
                    ))}
                </>
            )}
        </>
    );
};

export default PDFUploader;