import React, { useEffect, useState } from 'react';
import { Modal, View, Dimensions, Image } from 'react-native';
import {
  ActivityIndicator,
  Provider as PaperProvider,
} from 'react-native-paper';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { useCommunity } from './ContextCommunity/CommunityContext';
import { Feather } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import CardCommunity from './CardCommunity';
import Button from '../../components/Button';
import THEME from '../../config/theme';
import * as S from './style';
import {
  Container,
  SmallText,
  StandardText,
} from '../../config/theme/globalStyles';
import Header from '../../components/Header';
import TextInput from '../../components/TextInput';

const Community = () => {
  const {
    isPostModalVisible,
    setIsPostModalVisible,
    textContent,
    setTextContent,
    image,
    posts,
    loading,
    isUser,
    openImagePicker,
    closeModal,
    handlePost,
    isAnonymous,
    messageImageError,
    loadingCard,
    setPosts,
    handleDeletePost,
    handleLike,
  } = useCommunity();

  const navigation = useNavigation();
  const [windowWidth, setWindowWidth] = useState(
    Dimensions.get('window').width
  );

  useEffect(() => {
    const updateDimensions = () => {
      setWindowWidth(Dimensions.get('window').width);
    };

    Dimensions.addEventListener('change', updateDimensions);

    return () => {
      Dimensions.removeEventListener('change', updateDimensions);
    };
  }, []);

  if (isAnonymous) {
    return (
      <Container
        justifyContent="center"
        background={THEME.COLORS.BACKGROUND_MAIN}
      >
        <StandardText color={THEME.COLORS.TEXT_MAIN}>
          Ops, acesso negado!
        </StandardText>
        <SmallText margin="2rem" color={THEME.COLORS.TEXT_MAIN}>
          Se você já possui cadastro, clique no botão abaixo para fazer login
          com o seu email e senha:
        </SmallText>
        <Button
          title={'Login'}
          onPress={() => navigation.navigate('Login')}
          colorbutton={THEME.COLORS.PRIMARY_800}
          colortitle={THEME.COLORS.TEXT_BUTTON}
        ></Button>
      </Container>
    );
  }

  return (
    <PaperProvider>
      {loadingCard ? (
        <ActivityIndicator
          style={{
            flex: 1,
            backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
            justifyContent: 'center',
          }}
          color={THEME.COLORS.PRIMARY_800}
        />
      ) : (
        <S.ContainerCommunity>
          <View>
            <Header
              onPress={() => {
                isUser.isAdmin ?
                  navigation.dispatch(
                    CommonActions.reset({
                      routes: [{ name: 'Painel' }],
                    })
                  )
                  :
                  navigation.navigate('Conteudo', { screen: 'Aplicativo' });
              }}
              user={isUser}
            />
          </View>

          <S.Container>
            <S.Touch onPress={() => setIsPostModalVisible(true)}>
              <Feather name="edit" size={30} color={THEME.COLORS.TEXT_BUTTON} />
            </S.Touch>
            {posts.length === 0 ? (
              <StandardText color={THEME.COLORS.TEXT_MAIN}>
                Bem vindo à comunidade, clique no botão e faça seu primeiro post
              </StandardText>
            ) : (
              <CardCommunity
                posts={posts}
                handleLike={handleLike}
                handleDeletePost={handleDeletePost}
                user={isUser}
                setPosts={setPosts}
              />
            )}
            <Modal
              visible={isPostModalVisible}
              animationType="fade"
              transparent={true}
            >
              <S.ModalBackground>
                <S.ModalContainer windowWidth={windowWidth}>
                  <S.ModalContent>
                    <StandardText color={THEME.COLORS.TEXT_MAIN}>
                      NOVO POST:
                    </StandardText>

                    <S.SelectImageBtn onPress={openImagePicker}>
                      {image ? (
                        <Image
                          source={{ uri: image }}
                          style={{
                            width: '100%',
                            aspectRatio: 1,
                            marginBottom: 10,
                          }}
                          resizeMode="contain"
                        />
                      ) : (
                        <Ionicons
                          name="md-image"
                          size={80}
                          color={THEME.COLORS.TEXT_MAIN}
                        />
                      )}
                      {!image && (
                        <S.SelectImageText>
                          Clique para adicionar uma imagem
                        </S.SelectImageText>
                      )}
                      {messageImageError && (
                        <SmallText color={THEME.COLORS.ALERT}>
                          O tipo de arquivo selecionado é inválido
                        </SmallText>
                      )}
                    </S.SelectImageBtn>
                    <S.ViewTextInput>
                      <TextInput
                        placeholder="Escreva uma legenda"
                        keyboardType="default"
                        returnKeyType="go"
                        onChangeText={setTextContent}
                        value={textContent}
                        multiline={true}
                        scrollEnabled={true}
                      />
                    </S.ViewTextInput>

                    <S.ButtonContainer>
                      <Button
                        title={'Enviar'}
                        onPress={handlePost}
                        isLoading={loading}
                      />
                      <Button
                        title={'Cancelar'}
                        onPress={closeModal}
                      />
                    </S.ButtonContainer>
                  </S.ModalContent>
                </S.ModalContainer>
              </S.ModalBackground>
            </Modal>
          </S.Container>
        </S.ContainerCommunity>
      )}
    </PaperProvider>
  );
};

export default Community;
