import React, { useState } from 'react';
import { Container, FormMessage, ViewButton, ViewText, ViewHelper, ViewTextInput } from './style';
import { emailValidator } from '../../../utils';
import TextInput from '../../../components/TextInput';
import Button from '../../../components/Button';
import { HelperText } from 'react-native-paper';
import { SubTitle, SmallText } from '../../../config/theme/globalStyles';
import { SafeAreaView, Linking, TouchableOpacity, Text } from "react-native"; // Importe Linking e TouchableOpacity

import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

export function ResetPassword({ isAdmin }) {
  const [email, setEmail] = useState({ value: '', error: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [result, setResult] = useState(null);

  const messageVariants = {
    hidden: { y: 30, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { delay: 0.2, duration: 0.4 } },
  };

  const onResetPressed = () => {
    const emailError = emailValidator(email.value);
    setLoading(true);
    if (emailError) {
      setEmail({ ...email, error: emailError });
      setLoading(false);
      return;
    }
    // Chamar função HTTPS callable
    const sendPasswordResetEmail = httpsCallable(
      functions,
      "sendPasswordResetEmail"
    );
    sendPasswordResetEmail({ email: email.value })
      .then((response) => {
        if (response.data && response.data.resetLink) {
          if (isAdmin) {
            setResult(response.data.resetLink);
          } else {
            setResult(`Enviamos um e-mail de recuperação de senha para este email.`);
          }
        } else {
          setError('Não foi possível obter o link de redefinição de senha. Por favor, tente novamente.');
        }
      })
      .catch((error) => {
        console.error('Error sending password reset email:', error);
        setError('Erro ao enviar o e-mail de recuperação de senha. Por favor, tente novamente.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Função para lidar com o pressionamento do link
  const handleLinkPress = () => {
    if (result) {
      Linking.openURL(result);
    }
  };

  return (
    <SafeAreaView>
      <Container>
        <SubTitle textAlign="left" margin="0rem 0rem 2rem 0rem">Redefina a sua senha:</SubTitle>
        <ViewTextInput>
          <ViewText>
            <TextInput
              label="Email"
              returnKeyType="next"
              value={email.value}
              onChangeText={text => setEmail({ value: text, error: '' })}
              error={!!email.error}
              autoCapitalize="none"
              autoCompleteType="email"
              textContentType="emailAddress"
              keyboardType="email-address"
            />
          </ViewText>
          <ViewHelper>
            <HelperText type="error" visible={!!email.error}>{email.error}</HelperText>
          </ViewHelper>
        </ViewTextInput>
        <ViewButton>
          <Button
            title={isAdmin ? 'Gerar link' : 'Enviar'}
            isLoading={loading}
            onPress={onResetPressed}
          />
        </ViewButton>
        <ViewButton>
          {error && (
            <FormMessage
              variants={messageVariants}
              initial="hidden"
              animate="animate"
              error
            >
              {error}
            </FormMessage>
          )}
          {result && (
            isAdmin ? (
              <>
                <SmallText margin="1rem 0rem">Clique no link abaixo para redefinir:</SmallText>
                <TouchableOpacity onPress={handleLinkPress}>
                  <Text style={{ color: 'blue', textDecorationLine: 'underline', alignSelf: 'center' }}>{result}</Text>
                </TouchableOpacity>
              </>
            ) : (
              <FormMessage
                variants={messageVariants}
                initial="hidden"
                animate="animate"
              >
                {result}
              </FormMessage>
            )
          )}
        </ViewButton>
      </Container>
    </SafeAreaView>
  );
}