import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { StandardText, SubTitle } from "../../../config/theme/globalStyles";
import DataTable from 'react-data-table-component';
import VideoChart from "../../../components/Chart";
import { Container } from "../style";
import Header from "../../../components/Header";
import THEME from "../../../config/theme";
import { getDoc, doc, collection, getDocs } from "firebase/firestore";
import { firestore } from "../../../services/firebase";
import { ActivityIndicator } from "react-native-paper";

export function UsersAnalytics({ route, navigation }) {
    const { userId, userName } = route.params;

    const [formattedVideos, setFormattedVideos] = useState([]);
    const [pending, setPending] = useState(true);

    const fetchContentsForUser = async (userId) => {
        const userDocRef = doc(firestore, "users", userId);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
            const contentsCollectionRef = collection(userDocRef, "contents");
            const contentsSnapshot = await getDocs(contentsCollectionRef);

            const watchedContents = contentsSnapshot.docs
                .filter((contentDoc) => contentDoc.data().isWatched)
                .map((contentDoc) => ({
                    categoryId: contentDoc.data().categoryId,
                    cardId: contentDoc.data().cardId,
                    videoId: contentDoc.id,
                    markedAsWatchedAt: contentDoc.data()?.markedAsWatchedAt
                }))
                .filter(content => content.categoryId && content.cardId && content.videoId); // Filtrando conteúdos com IDs válidos

            return { id: userDocSnapshot.id, watchedContents };
        } else {
            return null; // Documento do usuário não existe
        }
    };

    const fetchVideoTitlesOfUser = async (userWithContents) => {
        const { watchedContents } = userWithContents;
        const watchedVideos = await Promise.all(
            watchedContents.map(async (content) => {
                const videosCollectionPath = `categories/${content.categoryId}/cards/${content.cardId}/videos`;
                const videoDocRef = doc(firestore, videosCollectionPath, content.videoId);
                const videoDocSnapshot = await getDoc(videoDocRef);

                if (videoDocSnapshot.exists()) {
                    return videoDocSnapshot.data().title;
                } else {
                    return null;
                }
            })
        );

        return { ...userWithContents, watchedVideos: watchedVideos.filter(Boolean) };
    };

    useEffect(() => {
        const fetchUserContents = async (userId) => {
            const userContents = await fetchContentsForUser(userId);
            if (userContents) {
                const userVideoTitles = await fetchVideoTitlesOfUser(userContents);

                const formattedVideos = userVideoTitles.watchedVideos.map((title, index) => ({
                    title,
                    date: new Date(userContents.watchedContents[index].markedAsWatchedAt.seconds * 1000),
                }));

                // Ordenar o array formattedVideos por data
                formattedVideos.sort((a, b) => a.date - b.date);

                formattedVideos.forEach((video) => {
                    video.date = video.date.toLocaleDateString();
                });

                if (formattedVideos && formattedVideos.length > 0) {
                    setFormattedVideos(formattedVideos);
                }

                setPending(false);
            } else {
                console.error("User not found");
                // Handle the case where user is not found
            }
        };

        fetchUserContents(userId).catch((error) => {
            console.error("Error fetching user:", error);
            // Handle the error or show a notification to the user
        });
    }, [userId]);

    const customStyles = {
        rows: {
            style: {
                color: "#000000",
                fontFamily: THEME.FONTFAMILY.LIGHT,
                fontSize: THEME.FONTSIZE.EXTRASMALL
            },
        },
        headCells: {
            style: {
                color: "#000000",
                fontFamily: THEME.FONTFAMILY.MEDIUM,
                fontSize: THEME.FONTSIZE.EXTRASMALL
            },
        },
        cells: {
            style: {},
        },
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Linhas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    return (
        <Container>
            <Header
                onPressEditProfile={() => navigation.navigate("Admin", { screen: "Usuários" })}
            />
            <SubTitle textAlign="left" padding="1rem">Nome do usuário: {userName}</SubTitle>
            <View style={{ flex: 1, alignItems: 'center', padding: "1rem" }}>
                <View style={{ width: '100%', paddingBottom: 20 }}>
                    <StandardText textDecoration="underline">Vídeos Assistidos:</StandardText>
                    <DataTable
                        columns={[
                            {
                                name: 'Título do Vídeo',
                                selector: row => row.title, // Use um seletor de função aqui
                                sortable: true,
                            },
                            {
                                name: 'Data que assistiu',
                                selector: row => row.date, // Use um seletor de função aqui
                                sortable: true,
                            },
                        ]}
                        data={formattedVideos}
                        highlightOnHover
                        pointerOnHover
                        customStyles={customStyles}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        progressPending={pending}
                        progressComponent={
                            <ActivityIndicator
                                style={{ padding: "5rem" }}
                                color={THEME.COLORS.PRIMARY_900}
                                size="large"
                            />
                        }
                    />
                </View>
                <StandardText textDecoration="underline">Gráfico:</StandardText>
                <VideoChart data={formattedVideos} />
            </View>
        </Container>
    );
}