import styled from 'styled-components/native';

export const Card = styled.View`
  background-color: ${({ theme }) => theme.COLORS.BACKGROUND_MAIN};
  width: 60%;
  border-radius: 10px;
  shadow-color: rgba(0, 0, 0, 0.5);
  shadow-offset: 0px 2px; 
  shadow-opacity: 0.5;
  shadow-radius: 10px; 
  elevation: 5;
  margin: auto;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
  overflow: hidden;
`;


export const ContentContainer = styled.View`
  padding-horizontal: 8px;
  padding-vertical: 12px;
  align-items: flex-start;
`;

export const ContentProfile = styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  
`;

export const BoxProfile = styled.View`
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const ImageProfile = styled.Image`
  width: 40px;
  height: 40px;
  border-radius: 100px;
`;

export const ImagePost = styled.Image`
  width: 80%;
  aspect-ratio: 16/9;
  resize-mode: contain;
  margin: auto
`;

export const ActionsContainer = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-horizontal: 8px;
  padding-vertical: 8px;
`;

export const LeftActionsContainer = styled.View`
  flex-direction: row;
  align-items: center;
`;