import React, { useState, useEffect } from 'react';
import { TextInput as Input } from 'react-native-paper';
import THEME from '../../config/theme';

const TextInput = (props) => {
  const [inputHeight, setInputHeight] = useState(40);
  const [textValue, setTextValue] = useState(props.value || '');

  useEffect(() => {
    setTextValue(props.value || '');
  }, [props.value]);

  const handleTextChange = (text) => {
    setTextValue(text);
    if (props.onChangeText) {
      props.onChangeText(text);
    }
    const numberOfLines = text.split('\n').length;
    const newHeight = numberOfLines * 20 + 20;
    setInputHeight(Math.max('6rem', newHeight));
  };

  return (
    <Input
      {...props}
      style={{
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : THEME.COLORS.BACKGROUND_ABOUT,
        fontFamily: THEME.FONTFAMILY.REGULAR,
        fontSize: THEME.FONTSIZE.SMALL,
        width: props.width,
        height: props.multiline ? Math.max(40, inputHeight) : '',
        borderBottomColor: props.mode === 'flat' ? THEME.COLORS.PRIMARY_900 : "",
        borderBottomWidth: props.mode === 'flat' ? 1 : 0,
      }}
      mode={props.mode ? props.mode : 'outlined'}
      underlineColor={props.mode === 'flat' ? THEME.COLORS.PRIMARY_800 : undefined}
      theme={{
        colors: {
          primary: props.color ? props.color : THEME.COLORS.PRIMARY_900,
          outline: props.color ? props.color : THEME.COLORS.PRIMARY_800,
          onSurfaceVariant: props.color ? props.color : THEME.COLORS.TEXT_ABOUT,
          onSurface: props.color ? props.color : THEME.COLORS.TEXT_ABOUT,
        },
      }}
      multiline={props.multiline}
      value={textValue}
      onChangeText={handleTextChange}
      onContentSizeChange={(event) => {
        if (props.multiline) {
          setInputHeight(event.nativeEvent.contentSize.height);
        }
      }}
    />
  );
};

export default TextInput;