import React, { useEffect, useState } from 'react';
import { onAuthStateChanged, signInAnonymously, signOut } from "firebase/auth";
import { Linking } from 'react-native';
import { DrawerContentScrollView, DrawerItemList, DrawerItem } from '@react-navigation/drawer';
import { MaterialIcons, MaterialCommunityIcons } from '@expo/vector-icons';
import { Footer } from './style';
import THEME from '../../config/theme';
import { auth, firestore } from '../../services/firebase';
import { SmallText, FooterText, Container } from '../../config/theme/globalStyles';
import { drawerClosedLinks, drawerOpenedLinks } from "../../config/data";
import { doc, getDoc } from "firebase/firestore";
import { A } from '@expo/html-elements';

export function DrawerContent(props) {
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState();

  const logout = () => {
    signOut(auth).then(() => {
      window.location.assign("../About");
    }).catch((error) => {
      console.log(error);
    });
  };

  const getUserInformation = async (userId) => {
    const docRef = doc(firestore, "users", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUser(docSnap.data());
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        setLogin(true);
        getUserInformation(user.uid);
      } else if (!user) {
        // Nenhum usuário está logado, realizar login anônimo.
        signInAnonymously(auth)
          .then(() => {
            // Login anônimo realizado com sucesso.
            console.log('Usuário logado anonimamente');
          })
          .catch((error) => {
            // Houve um erro ao realizar login anônimo.
            console.error(error);
          });
      }
    });
  }, []);

  return (
    <Container>
      <DrawerContentScrollView {...props}>
        <DrawerItemList {...props} />
        {drawerOpenedLinks.map((item, index) => (
          <A key={`opened-link-${index}`} href={item.link}>
            <DrawerItem
              label={() => (<SmallText color={THEME.COLORS.DRAWER_SELECTED} textAlign="left">{item.title}</SmallText>)}
            />
          </A>
        ))}
        {user && !user.isAdmin && drawerClosedLinks.map((item, index) => (
          <DrawerItem
            key={`closed-link-${index}`}
            label={() => (<SmallText color={THEME.COLORS.DRAWER_SELECTED} textAlign="left">{item.title}</SmallText>)}
            onPress={() => Linking.openURL(item.link)}
          />
        ))}
      </DrawerContentScrollView>
      {login ?
        <DrawerItem
          label={() => (
            <SmallText color={THEME.COLORS.DRAWER_SELECTED} textAlign="left" onPress={logout}>Sair</SmallText>
          )}
          icon={({ size }) => (
            <MaterialIcons
              name="logout"
              color={THEME.COLORS.DRAWER_SELECTED}
              size={size}
              onPress={logout}
            />
          )}
        />
        :
        <></>
      }
      <Footer>
        <FooterText padding="0rem 0.2rem">Desenvolvido com</FooterText>
        <MaterialCommunityIcons
          name="cards-heart"
          color={THEME.COLORS.TEXT_FOOTER}
          size={12}
        />
        <FooterText padding="0rem 0.2rem">pela</FooterText>
        <FooterText fontFamily={THEME.FONTFAMILY.BOLD} padding="0rem 0.2rem" onPress={() => Linking.openURL("https://www.instagram.com/blix.tecnologia/")}>BLIX</FooterText>
      </Footer>
    </Container>
  );
}