import styled from 'styled-components/native';
import THEME from '../../config/theme';

export const Container = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
    padding: '1rem',
    flexGrow: 1,
  },
})``;

export const ContentIcon = styled.TouchableOpacity`
  align-self: center;
  width: 100%;
  padding: 0.2rem;
  padding: 0.5rem 0rem;
  background-color: 'rgba(0,0,0,0.1)';
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
`;

export const ViewVideo = styled.View`
  height: 9rem;
  width: 16rem;
  align-self: center;
  margin: 1rem 0rem;
`;

export const ViewDescription = styled.View`
  justify-content: center;
`;

export const ViewButton = styled.View`
  padding: 0.5rem 0rem;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

export const ViewTextInput = styled.View`
  height: 5rem;
`;

export const ViewText = styled.View`
  height: 80%;
`;

export const ViewSchedulingStudents = styled.View`
  background-color: blue;
  height: 200px;
`;