import React, { useEffect, useState } from "react";
import {
  Avatar,
  Row,
  ViewLogo,
  Content,
  IconButton,
} from "./style";
import { Ionicons, Feather } from '@expo/vector-icons'
import THEME from "../../config/theme";
import { HeaderContainer, SmallText } from "../../config/theme/globalStyles";
import { DrawerActions, useNavigation } from "@react-navigation/native";
import TouchableText from "../../components/TouchableText";
import { Dimensions, TouchableOpacity, View } from "react-native";
import { aspectRatioLogoAbout, aspectRatioLogoMain, hasOutsideAboutPage } from "../../config/data";
import Button from "../Button";
import { auth, firestore } from "../../services/firebase";
import { signOut } from "firebase/auth";
import ViewPortProvider from "../../hooks/ViewPortProvider";
import useViewport from "../../hooks/useViewport";
import LogoAbout from "../../../assets/LogoAbout.png"
import LogoMain from "../../../assets/LogoMain.png"
import NotificationModal from './../Modals/NotificationModal';
import { useRemoteConfigContext } from "../../contexts/useRemoteConfigContext";
import { addDoc, collection } from "firebase/firestore";

const Header = ({ about, onPressEditProfile, onPress, user, activePage, isAdminPage }) => {
  const navigation = useNavigation();
  const [modalVisible, setModalVisible] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);

  const config = useRemoteConfigContext();
  const hasGeneralNotifications = config.hasGeneralNotifications;
  const hasCommunity = config.hasCommunity;

  const [isSmallScreen, setIsSmallScreen] = useState(
    Dimensions.get('window').width < 768
  );

  useEffect(() => {
    const updateLayout = () => {
      setIsSmallScreen(Dimensions.get('window').width < 768);
    };

    const dimensionHandler = Dimensions.addEventListener(
      'change',
      updateLayout
    );

    return () => {
      dimensionHandler?.remove();
    };
  }, []);


  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleSendNotification = async () => {
    const notificationData = {
      title,
      body: content,
      sendDate: new Date(),
      status: 'pending',
      type: 'schedulingAdmin',
    };

    try {
      setLoading(true);
      const notificationsRef = collection(firestore, 'notifications');
      await addDoc(notificationsRef, notificationData);
      handleCloseModal();
      setTitle('');
      setContent('');
    } catch (error) {
      console.error('Erro ao enviar a notificação:', error);
    } finally {
      setLoading(false);
    }
  };


  const drawerIconColor =
    activePage && activePage === 'Main'
      ? THEME.COLORS.ICON_HEADER_MAIN
      : THEME.COLORS.TEXT_ABOUT;

  const handleToggleDrawer = () => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        window.location.assign('../About');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const MobileOrDesktopComponent = () => {
    const { width } = useViewport();
    const breakpoint = 1080;
    return width < breakpoint ? (
      <HeaderContainer
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <ViewLogo>
          <Avatar
            resizeMode="contain"
            width={`${aspectRatioLogoAbout * 48}px`}
            source={LogoAbout}
          />
        </ViewLogo>
        <Content>
          {user ? (
            <>
              <View style={{ marginRight: '1rem' }}>
                <Button
                  title={'Acessar conteúdo'}
                  colorbutton={THEME.COLORS.S1_BUTTON_HEADER}
                  onPress={onPress}
                  colortitle={THEME.COLORS.S1_HEADER_CONTENT}
                  fontFamily={THEME.FONTFAMILY.REGULAR}
                  fontSize={THEME.FONTSIZE.SMALL}
                  padding="0.3rem"
                ></Button>
              </View>
              <Button
                title={'Sair'}
                colorbutton="transparent"
                colortitle={THEME.COLORS.S1_HEADER_LOGIN}
                onPress={logout}
                fontFamily={THEME.FONTFAMILY.REGULAR}
                fontSize={THEME.FONTSIZE.SMALL}
                padding="0.3rem"
              ></Button>
            </>
          ) : (
            <>
              <View style={{ marginRight: '1rem' }}>
                <Button
                  title={'Visualizar Conteúdo'}
                  colortitle={THEME.COLORS.S1_HEADER_CONTENT}
                  colorbutton={THEME.COLORS.S1_BUTTON_HEADER}
                  onPress={onPress}
                  fontFamily={THEME.FONTFAMILY.REGULAR}
                  fontSize={THEME.FONTSIZE.SMALL}
                  padding="0.3rem"
                ></Button>
              </View>
              <Button
                title={'Login'}
                colorbutton="transparent"
                colortitle={THEME.COLORS.S1_HEADER_LOGIN}
                onPress={() => navigation.navigate('Login')}
                fontFamily={THEME.FONTFAMILY.REGULAR}
                fontSize={THEME.FONTSIZE.SMALL}
                padding="0.3rem"
              ></Button>
            </>
          )}
        </Content>
      </HeaderContainer>
    ) : (
      <HeaderContainer>
        <Row height={3}>
          <Avatar
            resizeMode="contain"
            width={`${aspectRatioLogoAbout * 48}px`}
            source={LogoAbout}
          />
        </Row>
        <Row height={3}>
          {user ? (
            <>
              <View style={{ marginRight: '1rem' }}>
                <Button
                  title={'Acessar conteúdo'}
                  colorbutton={THEME.COLORS.S1_BUTTON_HEADER}
                  colortitle={THEME.COLORS.S1_HEADER_CONTENT}
                  onPress={onPress}
                  fontFamily={THEME.FONTFAMILY.REGULAR}
                  fontSize={THEME.FONTSIZE.SMALL}
                  padding="0.3rem"
                ></Button>
              </View>
              <Button
                title={'Sair'}
                colortitle={THEME.COLORS.S1_HEADER_LOGIN}
                colorbutton="transparent"
                onPress={logout}
                fontFamily={THEME.FONTFAMILY.REGULAR}
                fontSize={THEME.FONTSIZE.SMALL}
                padding="0.3rem"
              ></Button>
            </>
          ) : (
            <>
              <View style={{ marginRight: '1rem' }}>
                <Button
                  title={'Visualizar Conteúdo'}
                  colorbutton={THEME.COLORS.S1_BUTTON_HEADER}
                  colortitle={THEME.COLORS.S1_HEADER_CONTENT}
                  onPress={onPress}
                  fontFamily={THEME.FONTFAMILY.REGULAR}
                  fontSize={THEME.FONTSIZE.SMALL}
                  padding="0.3rem"
                ></Button>
              </View>
              <Button
                title={'Login'}
                colorbutton="transparent"
                colortitle={THEME.COLORS.S1_HEADER_LOGIN}
                onPress={() => navigation.navigate('Login')}
                fontFamily={THEME.FONTFAMILY.REGULAR}
                fontSize={THEME.FONTSIZE.SMALL}
                padding="0.3rem"
              ></Button>
            </>
          )}
        </Row>
      </HeaderContainer>
    );
  };

  return user?.isAdmin ? (
    <HeaderContainer>
      <Row height={3}>
        {onPress ? (
          <Row>
            <Feather
              name="chevron-left"
              color={THEME.COLORS.ICON_HEADER_CLICKCLASS}
              size={THEME.FONTSIZE.BIG}
              onPress={onPress}
            />
            <TouchableText
              onPress={onPress}
              title={'Voltar'}
              color={THEME.COLORS.ICON_HEADER_CLICKCLASS}
            ></TouchableText>
          </Row>
        ) : (
          <Feather
            name="menu"
            color={drawerIconColor}
            size={34}
            onPress={handleToggleDrawer}
          />
        )}
      </Row>

      <Row height={3}>
        {about && (
          <>
            <TouchableOpacity style={{ height: '3rem' }} onPress={onPress}>
              {aspectRatioLogoMain?.length > 0 && (
                <Avatar
                  resizeMode="contain"
                  source={LogoMain}
                  width={`${aspectRatioLogoMain * 48}px`}
                />
              )}
            </TouchableOpacity>
          </>
        )}
        {hasGeneralNotifications && !isAdminPage && (
          <>
            <IconButton onPress={handleOpenModal}>
              <Ionicons
                name={'notifications-outline'}
                size={32}
                color={THEME.COLORS.ICON_HEADER_MAIN}
              />
              {!isSmallScreen && (
                <SmallText color={THEME.COLORS.ICON_HEADER_MAIN} padding="0rem 0.3rem">Notificações</SmallText>
              )}
            </IconButton>

            <NotificationModal
              modalVisible={modalVisible}
              handleCloseModal={handleCloseModal}
              handleSendNotification={handleSendNotification}
              loading={loading}
              setTitle={setTitle}
              setContent={setContent}
              title={title}
              content={content}
            />
          </>
        )}
        {hasCommunity && !isAdminPage && (
          <IconButton
            onPress={() => {
              navigation.navigate('Community');
            }}
          >
            <Ionicons
              name="laptop-outline"
              size={30}
              color={THEME.COLORS.ICON_HEADER_MAIN}
            />
            {!isSmallScreen && <SmallText color={THEME.COLORS.ICON_HEADER_MAIN} padding="0rem 0.3rem">Comunidade</SmallText>}
          </IconButton>
        )}

        {aspectRatioLogoMain?.length > 0 && (
          <Avatar
            resizeMode="contain"
            source={LogoMain}
            width={`${aspectRatioLogoMain * 48}px`}
          />
        )}
      </Row>
    </HeaderContainer>
  ) : about ? (
    <ViewPortProvider>
      <MobileOrDesktopComponent />
    </ViewPortProvider>
  ) : (
    <HeaderContainer>
      <Row height={3}>
        {onPress ? (
          <Row>
            <Feather
              name="chevron-left"
              color={THEME.COLORS.ICON_HEADER_CLICKCLASS}
              size={THEME.FONTSIZE.BIG}
              onPress={onPress}
            />
            <TouchableText
              onPress={onPress}
              title={'Voltar'}
              color={THEME.COLORS.ICON_HEADER_CLICKCLASS}
            ></TouchableText>
          </Row>
        ) : onPressEditProfile ? (
          <Row>
            <Feather
              name="chevron-left"
              color={THEME.COLORS.ICON_HEADER}
              size={THEME.FONTSIZE.BIG}
              onPress={onPress}
            />
            <TouchableText
              onPress={onPressEditProfile}
              title={'Voltar'}
              color={THEME.COLORS.ICON_HEADER}
            ></TouchableText>
          </Row>
        ) : (
          <Feather
            name="menu"
            color={isAdminPage ? '#2e2e2e' : drawerIconColor}
            size={34}
            onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
          />
        )}
      </Row>

      {!isAdminPage &&
        <View
          style={{
            height: '3rem',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onPress={() => {
              navigation.navigate('Buscar');
            }}
          >
            <Ionicons
              name="ios-search-outline"
              size={30}
              color={THEME.COLORS.ICON_HEADER_MAIN}
            />
            {!isSmallScreen && <SmallText color={THEME.COLORS.ICON_HEADER_MAIN} padding="0rem 0.3rem">Buscar</SmallText>}
          </IconButton>
          {hasCommunity && (
            <IconButton
              onPress={() => {
                navigation.navigate('Community');
              }}
            >
              <Ionicons
                name="laptop-outline"
                size={30}
                color={THEME.COLORS.ICON_HEADER_MAIN}
              />
              {!isSmallScreen && <SmallText color={THEME.COLORS.ICON_HEADER_MAIN} padding="0rem 0.3rem">Comunidade</SmallText>}
            </IconButton>
          )}
          <TouchableOpacity
            style={{ height: '3rem' }}
            onPress={() => {
              hasOutsideAboutPage ? {} : navigation.navigate('Inicio');
            }}
          >
            {aspectRatioLogoMain?.length > 0 && (
              <Avatar
                resizeMode="contain"
                width={`${aspectRatioLogoMain * 48}px`}
                source={LogoMain}
              />
            )}
          </TouchableOpacity>
        </View>
      }
    </HeaderContainer>
  );
};

export default Header;
