import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import { doc, getDoc } from 'firebase/firestore';
import THEME from '../../../config/theme';
import { Feather, FontAwesome } from '@expo/vector-icons';
import { firestore } from '../../../services/firebase';
import { Avatar, ViewComment, ViewAvatar } from '../style';
import LogoMain from '../../../../assets/LogoMain.png';
import { aspectRatioLogoMain } from '../../../config/data';
import { FooterText } from '../../../config/theme/globalStyles';
import { Image } from 'react-native';
import { TouchableOpacity } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { useUser } from '../../../queries/userQuery';

const ReplyItem = ({ reply, handleDeleteReply, comment, user }) => {
  const [userComment, setUserComment] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { data: userData } = useUser();

  const fetchUser = async () => {
    const docRef = doc(firestore, 'users', reply.userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUserComment(docSnap.data());
      setIsLoading(false);
    } else {
      console.log('User does not exist');
    }
  };

  const route = useRoute();

  const id = route.params.id;

  useEffect(() => {
    fetchUser();
  }, []);

  const isAdminOrCurrentUser = () => {
    if (userData?.isAdmin || user?.id === reply?.userId) {
      return true;
    } else {
      return false;
    }
  };

  return (
    !isLoading && (
      <ViewComment>
        <ViewAvatar>
          {(userData?.isAdmin && reply?.userId === userData?.id) ? (
            <Avatar
              resizeMode="contain"
              width={aspectRatioLogoMain * 48}
              source={LogoMain}
            />
          ) : (
            <>
              {userComment?.photo ? (
                <Image
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 100,
                    marginRight: 8,
                  }}
                  source={{ uri: userComment?.photo }}
                />
              ) : (
                <Feather
                  name="user"
                  size={24}
                  color={THEME.COLORS.PRIMARY_900}
                />
              )}
            </>
          )}
        </ViewAvatar>
        <View style={{ flex: 1, paddingLeft: 5 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <FooterText
              color={THEME.COLORS.TITLE_MAIN}
              textAlign="left"
              fontFamily={THEME.FONTFAMILY.MEDIUM}
            >
              {userComment?.Nome_Completo}
            </FooterText>

            {isAdminOrCurrentUser() && (
              <View>
                <TouchableOpacity
                  onPress={() =>
                    handleDeleteReply(
                      reply.commentId || comment.id,
                      reply.id,
                      id
                    )
                  }
                >
                  <FontAwesome
                    name="trash-o"
                    size={22}
                    color={THEME.COLORS.TITLE_MAIN}
                  />
                </TouchableOpacity>
              </View>
            )}
          </View>
          <FooterText color={THEME.COLORS.TEXT_MAIN} textAlign="left">
            {reply.comment || reply.reply}
          </FooterText>
        </View>
      </ViewComment>
    )
  );
};

export default ReplyItem;
