import React, { useEffect, useState } from 'react';
import {
  Container,
  ViewButton,
  ViewText,
  ViewTextInput,
  ViewDescription,
  ViewVideo,
  ContentIcon,
} from './style';
import TextInput from '../TextInput';
import Button from '../Button';
import {
  SubTitle,
  SmallText,
  FooterText,
} from '../../config/theme/globalStyles';
import { MaterialIcons } from '@expo/vector-icons';
import THEME from '../../config/theme';
import { AlertBox } from '../AlertBox';
import { VideoUpload } from '../VideoAudioPicker';
import { ActivityIndicator, Provider, Chip } from 'react-native-paper';
import { PdfUpload } from '../PdfPicker';
import VideoPlayer from '../VideoPlayer';
import {
  TouchableOpacity,
  Modal,
  View,
  Text,
  Picker,
  TextInput as TextInputDefault,
} from 'react-native';
import { CheckBox } from 'react-native-elements';
import { Calendar, LocaleConfig } from 'react-native-calendars';
import { deleteObject, ref } from 'firebase/storage';
import { firestore, storage } from '../../services/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { PDFUploader } from '../PDFUploader'

export function EditVideo(props) {

  const {
    video,
    onSave,
    isSaving,
    onClose,
    categoryId,
    videoId,
    cardId,
    user,
    plans,
    products,
  } = props;

  // Criar um novo array contendo os objetos plans e products
  const combinedArray = [];

  // Adicionar os objetos plans ao novo array
  if (Array.isArray(plans.plans)) {
    combinedArray.push(
      ...plans.plans.map((item) => ({ ...item, title: item.title }))
    );
  }

  // Adicionar os objetos products ao novo array
  if (Array.isArray(products)) {
    combinedArray.push(
      ...products.map((item) => ({ ...item, title: `[Guru] ${item.title}` }))
    );
  }

  // Criar um novo array contendo apenas os títulos/nomes dos objetos
  const titlesArray = combinedArray.map((item) => {
    return item;
  });

  const videoUrl =
    video?.videoInfo?.url ||
    video?.pandaVideoMetadata?.video_player ||
    video?.link;


  const {
    title = '',
    url = '',
    meetingNumber = '',
    meetingPassword = '',
    link = '',
    description = [''],
    selectedPlans = [],
    selectedDate,
    isScheduling = null,
    isSchedulingNewStudents = null,
    pdf = [],
  } = video || {};

  const updatedSelectedDate = selectedDate
    ? new Date(selectedDate.seconds * 1000)
    : null;


  let updatedPdf;

  if (Array.isArray(pdf)) {
    updatedPdf = pdf;
  } else if (typeof pdf === 'string' && pdf.trim() === '') {
    updatedPdf = [];
  } else if (typeof pdf === 'string') {
    updatedPdf = [{ url: pdf, name: '' }];
  } else {
    updatedPdf = [pdf];
  }

   


  const [updatedVideo, setUpdatedVideo] = useState({
    title,
    url,
    meetingNumber,
    meetingPassword,
    link,
    description,
    selectedPlans,
    selectedDate: updatedSelectedDate,
    isScheduling,
    isSchedulingNewStudents,
    pdf: updatedPdf,
  });

  const descriptionInput = updatedVideo?.description || [''];
  const concatenatedDescription = descriptionInput?.join('\n');


  // Video Upload
  const [showVideoUploadModal, setShowVideoUploadModal] = useState(false);
  // PDF Upload
  const [showPDFUploadModal, setShowPDFUploadModal] = useState(false);

  const [showSaveSuccessAlert, setShowSaveSuccessAlert] = useState(false);

  const [selectedNumber, setSelectedNumber] = useState(
    video.schedulingDays ? video.schedulingDays : 0
  );

  const [selectedUnit, setSelectedUnit] = useState('dia');

  const unitArray = ['dia', 'semana', 'mês'];

  const [dateMessageError, setDateMessageError] = useState(false);

  const [messageErrorSchedule, setMessageErrorSchedule] = useState(false);

  const [planMessageError, setPlanMessageError] = useState(false);

  const [checkBoxDate, setCheckBoxDate] = useState(
    video.isScheduling ? video.isScheduling : false
  );
  const [checkBoxNewStudents, setcheckBoxNewStudents] = useState(
    video.isSchedulingNewStudents ? video.isSchedulingNewStudents : false
  );

  const dateModal = new Date(video.selectedDate?.seconds * 1000);
  const dia = dateModal?.getDate();
  const mes = dateModal?.getMonth() + 1;
  const ano = dateModal?.getFullYear();

  const dateFullModal = `${dia}/${mes}/${ano}`;

  const handleDateSelect = (date) => {
    const today = new Date();
    const todayStart = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    );

    const selectedDate = new Date(date.timestamp);
    const offset = selectedDate.getTimezoneOffset();

    selectedDate.setMinutes(selectedDate.getMinutes() + offset);
    const selectedDateStart = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate()
    );

    if (selectedDateStart >= todayStart) {
      setUpdatedVideo((prevVideo) => ({
        ...prevVideo,
        selectedDate: selectedDate,
      }));
      setDateMessageError(false);
    } else {
      setDateMessageError(true);
    }
  };

  const convertDaysSchedulingDays = (number) => {
    let schedulingDays = 0;
    if (selectedUnit === 'dia') {
      schedulingDays = number;
    }
    if (selectedUnit === 'semana') {
      schedulingDays = number * 7;
    }
    if (selectedUnit === 'mês' || selectedUnit === 'mes') {
      schedulingDays = number * 30;
    }

    if (checkBoxNewStudents) {
      setUpdatedVideo((prevVideo) => ({
        ...prevVideo,
        schedulingDays,
      }));
    }
  };

  useEffect(() => {
    convertDaysSchedulingDays(selectedNumber);
  }, [selectedUnit, selectedNumber]);

  const handleNumber = (number) => {
    const parsedNumber = parseFloat(number);

    if (!isNaN(parsedNumber)) {
      setSelectedNumber(parsedNumber);
      convertDaysSchedulingDays(parsedNumber);
    } else {
      console.log('O valor fornecido não é um número válido.');
    }
  };

  const incrementNumber = () => {
    setSelectedNumber(selectedNumber + 1);
    convertDaysSchedulingDays(selectedNumber + 1);
  };

  const decrementNumber = () => {
    if (selectedNumber > 0) {
      setSelectedNumber(selectedNumber - 1);
    }
    convertDaysSchedulingDays(selectedNumber - 1);
  };

  const handleOnSaveClick = async () => {
    const { isScheduling, selectedDate } = updatedVideo;

    if (isScheduling && selectedDate == null) {
      setMessageErrorSchedule(true);
    } else {
      await onSave(updatedVideo);
      setMessageErrorSchedule(false);
      setShowSaveSuccessAlert(true);
    }
  };

  const handlePickerChange = (itemValue) => {
    if (itemValue !== '') {
      const selectedPlanObject = titlesArray.find(
        (item) => item.title === itemValue
      );

      if (
        !updatedVideo.selectedPlans?.some(
          (item) => item.title === selectedPlanObject.title
        )
      ) {
        //ALTEREI A IMPLEMENTAÇÃO DOS PLANOS AQUI
        setUpdatedVideo((prevVideo) => ({
          ...prevVideo,
          selectedPlans: [...prevVideo.selectedPlans, selectedPlanObject],
        }));
      }
    }
  };

  const updatedPlansInVideo = () => {
    setUpdatedVideo((prevVideo) => ({
      ...prevVideo,
      selectedPlans,
    }));
  };

  const handleCheckBoxDate = (value) => {
    updatedPlansInVideo();
    setCheckBoxDate(value);
    setcheckBoxNewStudents(false);
    value === true
      ? setUpdatedVideo(() => ({
        ...updatedVideo,
        isScheduling: value,
        isSchedulingNewStudents: !value,
      }))
      : setUpdatedVideo(() => ({
        ...updatedVideo,
        isScheduling: value,
      }));
  };

  const handleCheckBoxNewStudents = (value) => {
    updatedPlansInVideo();
    setcheckBoxNewStudents(value);
    setCheckBoxDate(false);
    value === true
      ? setUpdatedVideo(() => ({
        ...updatedVideo,
        isSchedulingNewStudents: value,
        isScheduling: !value,
      }))
      : setUpdatedVideo(() => ({
        ...updatedVideo,
        isSchedulingNewStudents: value,
      }));
  };

  const renderArrow = (direction) => {
    return (
      <View>
        <Text>{direction === 'left' ? '<' : '>'}</Text>
      </View>
    );
  };

  LocaleConfig.locales['pt-br'] = {
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    dayNames: [
      'Domingo',
      'Segunda-feira',
      'Terça-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sábado',
    ],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    today: 'Hoje',
  };

  LocaleConfig.defaultLocale = 'pt-br';

  const namePdf = (item) => {
    if (!item.title && item.title !== '') {
      return item.name.slice(24);
    }
    if (item.name === item.title) {
      return item.name.slice(24);
    }
    return item.title;
  };

  const deletePdfFromStorage = async (pdfUrl) => {
    try {
      const pdfRef = ref(storage, pdfUrl);
      await deleteObject(pdfRef);

      const filterPdf = updatedVideo.pdf.filter((pdf) => pdf.url !== pdfUrl);
      setUpdatedVideo((prevVideo) => ({
        ...prevVideo,
        pdf: filterPdf,
      }));

      const videoDocRef = doc(
        firestore,
        `categories/${categoryId}/cards/${cardId}/videos/${videoId}`
      );
      const videoDoc = await getDoc(videoDocRef);

      if (videoDoc.exists()) {
        const pdfList = videoDoc.data().pdf;
        const updatedPdfList = pdfList.filter((pdf) => pdf.url !== pdfUrl);
        await updateDoc(videoDocRef, { pdf: updatedPdfList });
      } else {
        throw new Error('Vídeo não encontrado.');
      }
    } catch (error) {
      console.error('Error deleting PDF: ', error);
    }
  };

  const handleTitleChange = (text, index) => {
    const newPdf = [...updatedVideo.pdf];

    newPdf[index].title = text;

    setUpdatedVideo((prevState) => ({
      ...prevState,
      pdf: newPdf,
    }));
  };

  return (
    <Provider>
      <Container>
        <SubTitle
          padding="0rem 0rem 0.5rem 0rem"
          color={THEME.COLORS.TEXT_ABOUT}
        >
          Alterar Informações:
        </SubTitle>
        <ViewDescription>
          <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
            1. Título:
          </SmallText>
        </ViewDescription>
        <ViewTextInput>
          <TextInput
            placeholder="Digitar título"
            keyboardType="default"
            returnKeyType="go"
            value={updatedVideo.title}
            onChange={({ target: { value } }) => {
              setUpdatedVideo(() => ({ ...updatedVideo, title: value }));
            }}
          />
        </ViewTextInput>
        <ViewDescription>
          <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
            2. Descrição:
          </SmallText>
        </ViewDescription>
        {Array.isArray(description) && (
          <ViewDescription>
            <TextInput
              placeholder="Digitar descrição"
              keyboardType="default"
              returnKeyType="none"
              value={concatenatedDescription}
              multiline={true}
              onChangeText={(text) => {
                setUpdatedVideo((prevState) => {
                  return {
                    ...prevState,
                    description: [text],
                  };
                });
              }}
            />
          </ViewDescription>
        )}
        {video.type === 'live' ? (
          <>
            <ViewDescription>
              <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
                3. ID da Reunião do Zoom:
              </SmallText>
            </ViewDescription>
            <ViewTextInput>
              <TextInput
                placeholder="Digitar ID"
                keyboardType="default"
                returnKeyType="go"
                value={updatedVideo.meetingNumber}
                onChange={({ target: { value } }) => {
                  setUpdatedVideo(() => ({
                    ...updatedVideo,
                    meetingNumber: value,
                  }));
                }}
              />
            </ViewTextInput>
            <ViewDescription>
              <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
                4. Password do link da Reunião do Zoom:
              </SmallText>
            </ViewDescription>
            <ViewTextInput>
              <TextInput
                placeholder="Digitar o código após o 'pwd=' do link"
                keyboardType="default"
                returnKeyType="go"
                value={updatedVideo.meetingPassword}
                onChange={({ target: { value } }) => {
                  setUpdatedVideo(() => ({
                    ...updatedVideo,
                    meetingPassword: value,
                  }));
                }}
              />
            </ViewTextInput>
            <PDFUploader
              setShowPDFUploadModal={setShowPDFUploadModal}
              updatedVideo={updatedVideo}
              namePdf={namePdf}
              handleTitleChange={handleTitleChange}
              deletePdfFromStorage={deletePdfFromStorage}
            />
          </>
        ) : (
          <>
            <ViewDescription>
              <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
                3. Vídeo ou áudio:
              </SmallText>
            </ViewDescription>
            <ViewDescription>
              <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
                - Link do Vídeo/Áudio do Youtube ou Vimeo:
              </SmallText>
            </ViewDescription>
            <ViewTextInput>
              <ViewText>
                <TextInput
                  placeholder="Colar link do Youtube ou Vimeo"
                  keyboardType="default"
                  returnKeyType="go"
                  value={updatedVideo?.link}
                  onChange={({ target: { value } }) => {
                    setUpdatedVideo(() => ({
                      ...updatedVideo,
                      link: value,
                    }));
                  }}
                />
              </ViewText>
            </ViewTextInput>
            {props.planType === 'growth' || props.planType === 'premium' || props.planType === 'ownPanda' ? (
              <>
                <SmallText textAlign="center" color={THEME.COLORS.TEXT_ABOUT}>
                  OU
                </SmallText>
                <SmallText
                  textAlign="flex-start"
                  color={THEME.COLORS.TEXT_ABOUT}
                >
                  - Adicione um vídeo/áudio no banco de dados:
                </SmallText>

                {video.videoInfo === 'CONVERTING' ? (
                  <View style={{ alignItems: 'center' }}>
                    <SmallText>
                      Seu vídeo está sendo convertido e logo estará disponível
                      aqui
                    </SmallText>
                    <View>
                      <ActivityIndicator
                        style={{ marginTop: 10 }}
                        size="small"
                        color={THEME.COLORS.TEXT_ABOUT}
                      />
                    </View>
                  </View>
                ) : (
                  <ContentIcon onPress={() => setShowVideoUploadModal(true)}>
                    <MaterialIcons
                      name="drive-folder-upload"
                      size={24}
                      color={THEME.COLORS.TEXT_ABOUT}
                    />
                    <FooterText
                      color={THEME.COLORS.TEXT_ABOUT}
                      padding="0.5rem"
                    >
                      Upload Vídeo/Áudio
                    </FooterText>
                  </ContentIcon>
                )}
              </>
            ) : (
              <></>
            )}
            {videoUrl && (
              <ViewVideo>
                <VideoPlayer video={video} />
              </ViewVideo>
            )}
            <PDFUploader
              setShowPDFUploadModal={setShowPDFUploadModal}
              updatedVideo={updatedVideo}
              namePdf={namePdf}
              handleTitleChange={handleTitleChange}
              deletePdfFromStorage={deletePdfFromStorage}
            />
            <Modal
              animationType="fade"
              transparent={true}
              visible={showVideoUploadModal}
              onRequestClose={() => {
                setShowVideoUploadModal(false);
              }}
            >
              <VideoUpload
                url={videoUrl}
                onUploadCompleted={() => setShowVideoUploadModal(true)}
                onClose={() => setShowVideoUploadModal(false)}
                metadata={{
                  databasePath: `/categories/${categoryId}/cards/${cardId}/videos/${videoId}`,
                  databasePropName: 'videoInfo',
                }}
                user={user}
                video={video}
              ></VideoUpload>
            </Modal>
          </>
        )}
        <ViewDescription>
          <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
            (Opcional) Link de acesso externo:
          </SmallText>
          <FooterText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
            Aqui você pode colocar um link de acesso externo. Colocar o link com
            "https://"
          </FooterText>
        </ViewDescription>
        <ViewTextInput>
          <ViewText>
            <TextInput
              placeholder="Colar link"
              keyboardType="default"
              returnKeyType="go"
              value={updatedVideo.url}
              onChange={({ target: { value } }) => {
                setUpdatedVideo(() => ({
                  ...updatedVideo,
                  url: value,
                }));
              }}
            />
          </ViewText>
        </ViewTextInput>
        <ViewDescription>
          <SmallText textAlign="flex-start" color={THEME.COLORS.TEXT_ABOUT}>
            (Opcional) Agendar liberação do conteúdo:
          </SmallText>
        </ViewDescription>
        <View>
          {titlesArray?.length > 0 && (
            <View>
              <ViewDescription>
                <SmallText
                  textAlign="flex-start"
                  color={THEME.COLORS.TEXT_ABOUT}
                >
                  - Selecione abaixo o plano em que esse conteúdo será liberado
                  para o aluno:
                </SmallText>
              </ViewDescription>
              <Picker
                mode="dropdown"
                onValueChange={handlePickerChange}
                style={{
                  width: '100%',
                  height: '45px',
                  borderRadius: 8,
                  borderWidth: 1,
                  paddingLeft: 8,
                }}
                itemStyle={{
                  backgroundColor: 'white',
                  borderRadius: 8,
                }}
              >
                <Picker.Item label="" value="" />
                {titlesArray.map((item, index) => (
                  <Picker.Item
                    key={index}
                    label={item.title}
                    value={item.title}
                  />
                ))}
              </Picker>
              <View style={{ flex: 1 }}>
                <View
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    marginTop: 10,
                  }}
                >
                  {updatedVideo.selectedPlans &&
                    updatedVideo.selectedPlans.map((plan, index) => (
                      <Chip
                        key={index}
                        onClose={() => {
                          const updatedPlans =
                            updatedVideo.selectedPlans?.filter(
                              (item) => item !== plan
                            );
                          setUpdatedVideo((prevVideo) => ({
                            selectedPlans: updatedPlans,
                          }));
                        }}
                        style={{
                          marginRight: 8,
                          marginBottom: 8,
                          backgroundColor: 'gray',
                        }}
                      >
                        {plan.title}
                      </Chip>
                    ))}
                </View>
              </View>

              {video.isScheduling && (
                <View>
                  <SmallText color="#000000">
                    Este conteúdo foi agendado para:
                  </SmallText>
                  <SmallText color="#000000" fontFamily={THEME.FONTFAMILY.BOLD}>
                    {dateFullModal}
                  </SmallText>
                </View>
              )}

              {video.isSchedulingNewStudents && (
                <View>
                  <SmallText color="#000000">
                    Este conteúdo foi agendado para:
                  </SmallText>
                  <SmallText color="#000000" fontFamily={THEME.FONTFAMILY.BOLD}>
                    {video.schedulingDays} dias após a criação da conta
                  </SmallText>
                </View>
              )}
            </View>
          )}

          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <View
              style={{
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                backgroundColor: 'rgb(229, 229, 229)',
                padding: 16,
                borderRadius: 8,
                marginTop: '8px',
                width: '30%',
                opacity: checkBoxDate ? 0.5 : 1,
                minHeight: '420px',
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <CheckBox
                  checked={checkBoxNewStudents}
                  onPress={() => {
                    updatedVideo?.selectedPlans.length < 1
                      ? setPlanMessageError(true)
                      : (handleCheckBoxNewStudents(!checkBoxNewStudents),
                        setPlanMessageError(false));
                  }}
                  checkedColor="#008000"
                />

                <SmallText color={THEME.COLORS.TEXT_ABOUT}>
                  Liberação para novos alunos
                </SmallText>
              </View>
              {
                <View>
                  <ViewDescription>
                    {planMessageError && (
                      <FooterText color={THEME.COLORS.ALERT}>
                        Não é permitido criar um agendamento para novos alunos
                        se o card não possui nenhum plano selecionado.
                      </FooterText>
                    )}
                    <SmallText
                      textAlign="flex-start"
                      color={THEME.COLORS.TEXT_ABOUT}
                    >
                      - Selecione após quantos dias esse conteúdo será liberado
                      para novos alunos:
                    </SmallText>
                  </ViewDescription>
                  <View
                    style={{
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      marginTop: '8px',
                    }}
                  >
                    <View
                      style={{
                        flexDirection: 'row',
                        alignItems: 'left',
                        marginRight: 16,
                      }}
                    >
                      <TextInputDefault
                        style={{
                          backgroundColor: 'white',
                          padding: 8,
                          width: 70,
                          opacity: checkBoxNewStudents ? 1 : '0.5',
                        }}
                        disabled={checkBoxDate || !checkBoxNewStudents}
                        keyboardType="numeric"
                        value={selectedNumber}
                        onChangeText={handleNumber}
                        placeholder="dias"
                      />
                      <View
                        style={{
                          flexDirection: 'column',
                          backgroundColor: 'white',
                          borderLeftColor: '1px solid rgb(229, 229, 229)',
                        }}
                      >
                        <TouchableOpacity
                          style={{
                            height: 20,
                            width: 40,
                            borderWidth: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: 'none',
                          }}
                          onPress={incrementNumber}
                          disabled={checkBoxDate || !checkBoxNewStudents}
                        >
                          <Text>▲</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                          style={{
                            height: 20,
                            width: 40,
                            borderWidth: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            border: 'none',
                          }}
                          disabled={checkBoxDate || !checkBoxNewStudents}
                          onPress={decrementNumber}
                        >
                          <Text>▼</Text>
                        </TouchableOpacity>
                      </View>
                    </View>
                    <Picker
                      mode="dropdown"
                      selectedValue={selectedUnit}
                      onValueChange={(itemValue) => {
                        setSelectedUnit(itemValue);
                      }}
                      disabled={checkBoxDate || !checkBoxNewStudents}
                      style={{
                        backgroundColor: 'white',
                        width: '110px',
                        height: '45px',
                        borderWidth: 1,
                        paddingLeft: 8,
                        marginRight: '20px',
                        border: 'none',
                        marginTop: '20px',
                      }}
                    >
                      {unitArray.map((unit) => {
                        let label;

                        if (selectedNumber > 1) {
                          switch (unit) {
                            case 'dia':
                              label = 'dias';
                              break;
                            case 'semana':
                              label = 'semanas';
                              break;
                            case 'mês':
                              label = 'meses';
                              break;
                            default:
                              label = unit;
                          }
                        } else {
                          label = unit;
                        }

                        return (
                          <Picker.Item key={unit} label={label} value={unit} />
                        );
                      })}
                    </Picker>
                  </View>
                </View>
              }
            </View>

            <View
              style={{
                flexDirection: 'column',
                justifyContent: 'flex-start',
                backgroundColor: 'rgb(229, 229, 229)',
                padding: 16,
                borderRadius: 8,
                marginTop: '8px',
                width: '30%',
                marginLeft: 16,
                opacity: checkBoxNewStudents ? '0.5' : 1,
                minHeight: '420px',
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <CheckBox
                  checked={checkBoxDate}
                  onPress={() => handleCheckBoxDate(!checkBoxDate)}
                  checkedColor="#008000"
                />
                <SmallText color={THEME.COLORS.TEXT_ABOUT}>
                  Liberação por data
                </SmallText>
              </View>
              {
                <View
                  style={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Calendar
                    onDayPress={
                      !checkBoxNewStudents && checkBoxDate
                        ? handleDateSelect
                        : undefined
                    }
                    {...(updatedVideo.selectedDate instanceof Date &&
                      !isNaN(updatedVideo.selectedDate.getTime()) && {
                      markedDates: {
                        [updatedVideo.selectedDate
                          .toISOString()
                          .split('T')[0]]: {
                          selected: true,
                          selectedColor: '#008000',
                        },
                      },
                    })}
                    renderArrow={renderArrow}
                  />

                  {messageErrorSchedule && (
                    <FooterText color={THEME.COLORS.ALERT}>
                      Para realizar um agendamento você precisa selecionar pelo
                      menos uma data
                    </FooterText>
                  )}

                  {dateMessageError && (
                    <FooterText color={THEME.COLORS.ALERT}>
                      Não é permitido selecionar uma data anterior à data de
                      hoje.
                    </FooterText>
                  )}
                </View>
              }
            </View>
          </View>
        </View>
        <AlertBox
          message={'Alteração feita com sucesso! :)'}
          visible={showSaveSuccessAlert}
          leftButtonFunction={onClose}
          onClose={onClose}
          leftButton={'OK'}
        />
        <Modal
          animationType="fade"
          transparent={true}
          visible={showPDFUploadModal}
          onRequestClose={() => {
            setShowPDFUploadModal(false);
          }}
        >
          <PdfUpload
            onUploadCompleted={() => setShowPDFUploadModal(true)}
            onClose={() => setShowPDFUploadModal(false)}
            metadata={{
              databasePath: `/categories/${categoryId}/cards/${cardId}/videos/${videoId}`,
              databasePropName: 'pdf',
            }}
            updatedPdf={setUpdatedVideo}
          ></PdfUpload>
        </Modal>
        <ViewButton>
          <Button
            title={'Salvar'}
            isLoading={isSaving}
            onPress={handleOnSaveClick}
            margin="0rem 0.5rem"
          ></Button>
          <Button
            title={'Cancelar'}
            onPress={onClose}
            margin="0rem 0.5rem"
          ></Button>
        </ViewButton>
      </Container>
    </Provider>
  );
}
