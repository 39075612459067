import 'react-native-gesture-handler';
import Navigation from './src/routes';
import { ThemeProvider } from 'styled-components/native';
import THEME from './src/config/theme';
import { useFonts } from 'expo-font';
import { StatusBar } from 'expo-status-bar';
import { ActivityIndicator } from 'react-native-paper';
import text_Light_Font from './assets/fonts/Texto-Light.ttf';
import text_Regular_Font from './assets/fonts/Texto-Regular.ttf';
import text_Title_Font from './assets/fonts/TitleFont.ttf';
import text_Subtitle_Font from './assets/fonts/SubtitleFont.ttf';
import text_Texts_Font from './assets/fonts/TextFont.ttf';
import text_Medium_Font from './assets/fonts/Texto-Medium.ttf';
import text_Bold_Font from './assets/fonts/Texto-Bold.ttf';
import { RemoteConfigProvider } from "./src/contexts/useRemoteConfigContext"

import 'setimmediate';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export default function App() {
  const queryClient = new QueryClient();

  const [fontsLoaded] = useFonts({
    text_Light: text_Light_Font,
    text_Regular: text_Regular_Font,
    text_Medium: text_Medium_Font,
    text_Bold: text_Bold_Font,
    text_Title: text_Title_Font,
    text_Subtitle: text_Subtitle_Font,
    text_Texts: text_Texts_Font,
  });


  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={THEME}>
        {!fontsLoaded ? (
          <ActivityIndicator color="transparent" />
        ) : (
          <RemoteConfigProvider>
            <Navigation />
          </RemoteConfigProvider>
        )}
        <StatusBar style="auto" />
      </ThemeProvider>
    </QueryClientProvider>
  );
}
