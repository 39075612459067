import { collection, doc, endBefore, getCountFromServer, getDoc, getDocs, limit, orderBy, query, startAfter, where } from "firebase/firestore";
import { firestore } from "../../services/firebase/index";

export const getUsers = async (
    {
        pageReference,
        pageSize,
        isNextPage,
        email,
    }
) => {
    const usersCollection = collection(firestore, 'users');

    const queries = [
        orderBy("Email", "asc")
    ]

    if (!!pageSize) {
        queries.push(limit(pageSize))
    }

    if (!!isNextPage && !!pageReference) {
        queries.push(startAfter(pageReference))                
    }
    
    if (!isNextPage && !!pageReference) {
        queries.push(endBefore(pageReference))
    }  

    if (!!email && email != "") {
        queries.push(where("Email", "==", email))
    }

    return getDocs(
        query(usersCollection, ...queries)
    )
} 


export const getCountUsers = (
    {
        email,
    }
) => {
    const usersCollection = collection(firestore, 'users');
    const queries = [
        where("Email", "!=", null)
    ]

    if (!!email && email != "") {
        queries.push(where("Email", "==", email))
    } 

    return getCountFromServer(query(usersCollection, ...queries));
}


export const getUserById = (id) => {
    const docRef = doc(firestore, "users", id);
    return getDoc(docRef);
}


export const getUserContent = (userId) => {
    return getDocs(collection(firestore, "users", userId, "contents"));
}