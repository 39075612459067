import React, { useEffect, useState } from "react";
import { FlatList, TouchableOpacity } from "react-native";
import { VerticalScroll, VerticalScrollDesktop } from "../style";

import PlayList from "../../../components/PlayList";
import useViewport from "../../../hooks/useViewport";
import THEME from "../../../config/theme"
import { Title, StandardText } from "../../../config/theme/globalStyles";

const ViewFlatList = (props) => {
  const { user, card, videos, onVideoListClick, releaseDate, plansCard, productsCard } = props;
  const { coursesCard, plans, description, title, productIds } = card || {};
  const { plan: userPlan, courses: userCourses, isAdmin, productIds: userProductIds } = user || {};

  const [checkAccess, setCheckAccess] = useState(false);

  const { width } = useViewport();
  const breakpoint = 1080;
  const Container = width < breakpoint ? VerticalScroll : VerticalScrollDesktop;

  const toDate = (seconds) => {
    const date = new Date(1970, 0, 1);
    date.setSeconds(seconds);
    return date;
  };

  useEffect(() => {
    const handleCheckAccess = () => {
      // Verificar se o usuário tem o plano correspondente em plansCard
      const filteredPlan = plansCard?.plans?.some(({ productId }) => productId === user?.plan);
      // Verificar se o usuário tem o curso correspondente em coursesCard
      const coursesInCard = coursesCard?.map(({ productId }) => productId);
      const filteredCourses = user?.courses?.some(({ productId }) =>
        coursesInCard?.includes(productId)
      );
      // Verificar se o usuário tem permissão para um dos productIds em productsCard
      const productIdsInCard = productsCard?.map(({ productId }) => productId);
      const filteredProducts = user?.productIds?.some(({ productId }) =>
        productIdsInCard?.includes(productId)
      );
      setCheckAccess(filteredPlan || filteredProducts || filteredCourses || (!plansCard?.plans?.length && !productsCard?.length && !coursesCard?.length));
    }
    handleCheckAccess();
  }, []);


  const ListHeader = ({ title, description }) => {
    return (
      <>
        <Title color={THEME.COLORS.TITLE_MAIN} textAlign="left">
          {title}
        </Title>
        <StandardText
          color={THEME.COLORS.TEXT_MAIN}
          textAlign="left"
          margin="1rem 0rem 0rem 0rem"
          fontFamily={THEME.FONTFAMILY.LIGHT}
        >
          {description}
        </StandardText>
      </>
    );
  };

  return (
    <Container>
      {(productIds &&
        productIds?.length > 0 &&
        productIds?.filter(cardProductId => {
          const matchingUserProducts = userProductIds?.filter(userProduct => userProduct.productId === cardProductId);
          if (!matchingUserProducts || matchingUserProducts.length === 0) {
            return false; // O usuário não possui este produto, então não tem acesso
          }
          // Se não houver data de expiração, ou se alguma data de expiração for maior que a data atual, o usuário tem acesso
          return matchingUserProducts.some(userProduct => !userProduct.expirationDate || toDate(userProduct?.expirationDate.seconds) > new Date());
        }).length > 0
      ) ||
        (plans &&
          plans.length > 0 &&
          plans.filter((plan) => (plan.priceId || plan.productId) == userPlan).length > 0) ||
        (userCourses &&
          userCourses.length > 0 &&
          userCourses.filter(
            (userCourse) =>
              coursesCard?.some(
                (itemToBeRemoved) =>
                  (itemToBeRemoved.priceId || itemToBeRemoved.productId) === (userCourse.priceId || userCourse.productId)
              ) && toDate(userCourse?.dueDate.seconds) > new Date()
          ).length > 0) ||
        (plans == null && coursesCard == null && productIds == null) || isAdmin === true ? (
        <FlatList
          data={videos}
          keyExtractor={(item) => item.docId}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              style={{ marginVertical: '0.8rem' }}
              key={item.key}
              onPress={() => onVideoListClick(item)}
            >
              <PlayList
                {...item}
                video={item}
                user={user}
                releaseDate={releaseDate}
                hasAccess={checkAccess}
              ></PlayList>
            </TouchableOpacity>
          )}
          style={{ flexGrow: 0 }}
          ListHeaderComponent={
            <ListHeader title={title} description={description}></ListHeader>
          }
        />
      ) : (
        <FlatList
          data={videos}
          keyExtractor={(item) => item.docId}
          renderItem={({ item, index }) => (
            <TouchableOpacity
              style={{ marginVertical: '0.8rem' }}
              key={item.key}
            >

              <PlayList
                {...item}
                user={user}
                hasAccess={checkAccess}
                video={item}
              ></PlayList>
            </TouchableOpacity>
          )}
          style={{ flexGrow: 0 }}
          ListHeaderComponent={
            <ListHeader title={title} description={description}></ListHeader>
          }
        />
      )}
    </Container>
  );
};

export default ViewFlatList;
