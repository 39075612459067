import styled from 'styled-components/native';
import { Dimensions } from 'react-native';

const windowWidth = Dimensions.get('window').width;

export const Container = styled.View`
  flex: 1;
  background-color:   ${({ theme }) => theme.COLORS.BACKGROUND_MAIN} ;
  padding: 10px;
`;

export const CardContainer = styled.View`
  margin-bottom: 20px;
`;

export const CommentContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${windowWidth <= 768 ? '90%' : '60%'};
  background-color:  ${({ theme }) => theme.COLORS.BACKGROUND_MAIN} ;
  margin: auto;
  margin-bottom: 24px;
`;

export const ViewComment = styled.View`
  width: ${windowWidth <= 768 ? '90%' : '60%'};
  margin: auto;
`;
