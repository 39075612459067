import React, { useEffect, useState } from "react";
import { ActivityIndicator, Text } from "react-native";
import THEME from "../../../config/theme";
import { firestore } from "../../../services/firebase/index";
import Header from "../../../components/Header";
import {
    setDoc,
    addDoc,
    collection,
    getDocs,
    doc,
    orderBy,
    query,
    onSnapshot
} from "firebase/firestore";
import DataTable from 'react-data-table-component';
import TouchableText from "../../../components/TouchableText";
import CreateProductModal from "./CreateProductModal";
import EditProductModal from "./EditProductModal";
import { Container, ViewDataTable, ContentIcon } from "./style";
import { Provider } from "react-native-paper";

export function Products({ }) {
    const [products, setProducts] = useState([]);
    const [isCreateProductModalVisible, setIsCreateProductModalVisible] = useState(false);
    const [isEditProductModalVisible, setIsEditProductModalVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [loading, setLoading] = useState(true);

    const handleOpenCreateProductModal = () => {
        setIsCreateProductModalVisible(true);
    };

    const handleCloseCreateProductModal = () => {
        setIsCreateProductModalVisible(false);
    };

    const handleOpenEditProductModal = (row) => {
        setSelectedProduct(row);
        setIsEditProductModalVisible(true);
    };

    const handleCloseEditProductModal = () => {
        setIsEditProductModalVisible(false);
    };

    const handleCreateProduct = async (productInfo, offersInfos, productId) => {
        try {
            // Verificar se o produto já existe
            const productExists = products.some(product => product.productId === productId);
            if (productExists) {
                return { error: 'Você já possui este produto salvo na lista' };
            }

            const productRef = await addDoc(collection(firestore, 'products'), {
                name: productInfo.name,
                type: productInfo.type,
                productId: productId,
                marketplace: "Guru Digital",
                archived: true,
            });

            // Crie documentos na subcoleção 'offers' com as informações de cada oferta
            for (const offerInfo of offersInfos.data) {
                await addDoc(collection(productRef, 'offers'), offerInfo);
            }
            return { success: true };

        } catch (error) {
            console.error('Erro ao criar produto:', error);
            return { error: 'Erro ao criar produto' };
        }
    };

    const handleEditProduct = async (productInfo, offersInfos, hasExpirationDate, expirationDays, checkoutUrl, archived, description, selectedProduct) => {
        const productRef = doc(firestore, "products", selectedProduct?.id);
        try {
            const updateData = {
                marketplace: "Guru Digital",
                name: productInfo?.name,
                productId: productInfo?.marketplace_id,
                type: productInfo?.type,
            };

            if (hasExpirationDate !== undefined) {
                updateData.hasExpirationDate = hasExpirationDate;
            }
            if (expirationDays !== undefined) {
                updateData.days = expirationDays;
            }
            if (checkoutUrl !== undefined) {
                updateData.checkoutUrl = checkoutUrl;
            }
            if (archived !== undefined) {
                updateData.archived = archived;
            }
            if (description !== undefined) {
                updateData.description = description;
            }

            await setDoc(productRef, updateData, { merge: true });

            const offersCollectionRef = collection(productRef, 'offers');
            const offersSnapshot = await getDocs(offersCollectionRef);

            for (const offerInfo of offersInfos.data) {
                const offerId = offerInfo.id;
                const existingOfferDoc = offersSnapshot.docs.find(doc => doc.data().id === offerId);
                if (existingOfferDoc) {
                    await setDoc(doc(offersCollectionRef, existingOfferDoc.id), offerInfo, { merge: true });
                } else {
                    await addDoc(offersCollectionRef, offerInfo);
                }
            }
        } catch (error) {
            console.error('Erro ao atualizar documento:', error);
        }
    };

    const findAllProducts = async () => {
        const productsRef = collection(firestore, `products`);
        const q = query(productsRef, orderBy("name"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const updatedProducts = [];
            snapshot.forEach((doc) => {
                if (doc.data()?.marketplace === "Guru Digital") {
                    updatedProducts.push({ id: doc.id, ...doc.data() });
                }
            });
            setProducts(updatedProducts);
            setLoading(false);
        });
        return unsubscribe;
    };

    useEffect(() => {
        const fetchData = async () => {
            await findAllProducts();
        };
        fetchData();
    }, []);

    if (loading) return (
        <ActivityIndicator
            style={{
                flex: 1,
                backgroundColor: "#FFFFFF",
                justifyContent: "center",
            }}
            color={THEME.COLORS.PRIMARY_900}
            size="large"
        />
    );

    const conditionalRowStyles = [
        {
            when: row => row.archived !== false,
            style: {
                backgroundColor: '#ffffff' // Vermelho se archived for verdadeiro
            }
        },
        {
            when: row => row.archived === false,
            style: {
                backgroundColor: '#ffffff' // Verde se archived for falso
            }
        }
    ];

    return (
        <Provider>
            <Container>
                <Header navigation={navigation} isAdminPage/>
                {!isCreateProductModalVisible &&
                    <ContentIcon>
                        <TouchableText
                            onPress={() => handleOpenCreateProductModal()}
                            title="+"
                            color="#ffffff"
                            fontFamily={THEME.FONTFAMILY.BOLD}
                            fontSize={THEME.FONTSIZE.BIG}
                            margin="0rem 1rem"
                        ></TouchableText>
                    </ContentIcon>
                }
                {isCreateProductModalVisible &&
                    <CreateProductModal
                        isVisible={isCreateProductModalVisible}
                        onClose={() => {
                            handleCloseCreateProductModal();
                        }}
                        onCreate={handleCreateProduct}
                    />
                }
                {isEditProductModalVisible &&
                    <EditProductModal
                        isVisible={isEditProductModalVisible}
                        onClose={() => {
                            handleCloseEditProductModal();
                        }}
                        onEdit={handleEditProduct}
                        selectedProduct={selectedProduct}
                    />
                }
                <ViewDataTable>
                    <Text style={{ fontSize: 24, fontSize: '35px', marginBottom: '16px', marginTop: '16px', fontFamily: THEME.FONTFAMILY.TITLE }}>Produtos</Text>
                    <DataTable
                        style={{ backgroundColor: "#ffffff", borderRadius: 10 }}
                        columns={columns}
                        data={products}
                        highlightOnHover
                        pointerOnHover
                        striped={false}
                        customStyles={customStyles}
                        onRowClicked={(row, event) => handleOpenEditProductModal(row)}
                        conditionalRowStyles={conditionalRowStyles}
                    />
                </ViewDataTable>
            </Container>
        </Provider>
    );
}

const columns = [
    {
        name: 'Nome do Produto',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Código do Produto',
        selector: row => row.productId,
        sortable: true,
    },
    {
        name: 'Tipo',
        selector: row => row.type == "plan" ? "assinatura" : row.type == "product" ? "produto" : row.type,
        sortable: true,
    },
    {
        name: 'Status',
        selector: row => row.archived == true ? "Inativo" : "Ativo",
        sortable: true,
    },
];

const customStyles = {
    rows: {
        style: {
            color: "#757575",
            fontFamily: THEME.FONTFAMILY.MEDIUM,
            fontSize: THEME.FONTSIZE.MEDIUM,
            borderTopColor: 'white',
            borderBottomColor: 'white',
        },
    },
    headCells: {
        style: {
            color: "#000000",
            fontFamily: THEME.FONTFAMILY.MEDIUM,
            fontSize: THEME.FONTSIZE.STANDARD
        },
    },
    cells: {
        style: {
            borderTopColor: 'white',
            borderBottomColor: 'white',
        },
    },
    table: {
        style: {
            borderCollapse: 'collapse',
            borderRadius: '10px',
            overflow: 'hidden'
        }
    },
    dataTable: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        backgroundColor: '#FAFAFA',
    },
};