import React, { useState, useEffect } from 'react';
import { View, FlatList } from 'react-native';
import { doc, getDoc } from 'firebase/firestore';
import THEME from '../../../config/theme';
import { Feather, FontAwesome } from '@expo/vector-icons';
import { firestore } from '../../../services/firebase';
import { Avatar, ViewComment, ViewAvatar } from '../style';
import LogoMain from '../../../../assets/LogoMain.png';
import { aspectRatioLogoMain } from '../../../config/data';
import ReplyItem from '../ReplyItem';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { FooterText } from '../../../config/theme/globalStyles';
import { Image } from 'react-native';
import { useUser } from '../../../queries/userQuery';

const CommentItem = ({
  login,
  comment,
  editPlaceholder,
  setIsLoading,
  community,
  handleDeleteComment,
  handleDeleteReply,
  getDataReply,
  changeReply,
  idPost,
  isAdminOrIsUserCurrent,
  userId,
  handleDeleteReplyCard,
}) => {
  const [userComment, setUserComment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [commentCreatedAt, setCommentCreatedAt] = useState(null);

  const { data: userData } = useUser();

  const fetchUser = async () => {
    const docRef = doc(firestore, 'users', comment.userId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) return;
    setUserComment(docSnap.data());
    setLoading(false);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUser();
    if (comment.createdAt) {
      setCommentCreatedAt(
        timeAgo(new Date(comment.createdAt.toDate().toString()))
      );
    }
  }, []);

  const timeAgo = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);

    let interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
      return 'há ' + interval + ' anos';
    }

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return 'há ' + interval + ' meses';
    }

    interval = Math.floor(seconds / 86400);

    if (interval > 1) {
      return 'há ' + interval + ' dias';
    }

    interval = Math.floor(seconds / 3600);

    if (interval > 1) {
      return 'há ' + interval + ' horas';
    }

    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return 'há ' + interval + ' minutos';
    }

    if (seconds < 10) return 'agora';

    return 'há ' + Math.floor(seconds) + ' segundos';
  };

  return (
    !loading && (
      <ViewComment>
        <ViewAvatar>
          {(userData?.isAdmin && comment?.userId === userData?.id) ? (
            <Avatar
              resizeMode="contain"
              width={aspectRatioLogoMain * 48}
              source={LogoMain}
            />
          ) : (
            <>
              {userComment?.photo || userComment?.userPhoto ? (
                <Image
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 100,
                    marginRight: 8,
                  }}
                  source={{ uri: userComment?.photo || userComment?.userPhoto }}
                />
              ) : (
                <Feather
                  name="user"
                  size={24}
                  color={THEME.COLORS.PRIMARY_900}
                />
              )}
            </>
          )}
        </ViewAvatar>
        <View style={{ flex: 1, paddingLeft: 5 }}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <FooterText
              color={THEME.COLORS.TITLE_MAIN}
              textAlign="left"
              fontFamily={THEME.FONTFAMILY.REGULAR}
            >
              {userComment?.Nome_Completo}
            </FooterText>

            {comment?.userId &&
              typeof isAdminOrIsUserCurrent === 'function' &&
              idPost &&
              isAdminOrIsUserCurrent(comment.userId) && (
                <View style={{ cursor: 'pointer' }}>
                  <TouchableOpacity
                    onPress={() => handleDeleteComment(comment.id, idPost)}
                  >
                    <FontAwesome
                      name="trash-o"
                      size={22}
                      color={THEME.COLORS.TITLE_MAIN}
                    />
                  </TouchableOpacity>
                </View>
              )}
            {typeof isAdminOrIsUserCurrent === 'function' &&
              !idPost &&
              isAdminOrIsUserCurrent(userId, userData?.isAdmin, comment.userId) && (
                <View style={{ cursor: 'pointer' }}>
                  <TouchableOpacity
                    onPress={() => handleDeleteComment(comment.id)}
                  >
                    <FontAwesome
                      name="trash-o"
                      size={22}
                      color={THEME.COLORS.TITLE_MAIN}
                    />
                  </TouchableOpacity>
                </View>
              )}
          </View>
          <View>
            <FooterText color={THEME.COLORS.TEXT_MAIN} textAlign="left">
              {comment.comment}
            </FooterText>

            <View
              style={{
                flexDirection: 'row',
                paddingTop: 4,
                width: '100%',
              }}
            >
              <FooterText
                textAlign="left"
                color={THEME.COLORS.TEXT_MAIN}
                style={{ whiteSpace: 'nowrap' }}
              >
                {commentCreatedAt}
              </FooterText>
              {login && !community && (
                <TouchableOpacity
                  style={{ paddingLeft: '1rem' }}
                  onPress={() =>
                    editPlaceholder(
                      `Responder ${userComment?.Nome_Completo}`,
                      comment.id
                    )
                  }
                >
                  <FooterText textAlign="left" color={THEME.COLORS.TEXT_MAIN}>
                    Responder
                  </FooterText>
                </TouchableOpacity>
              )}

              {community && (
                <View style={{ width: '100%' }}>
                  <TouchableOpacity
                    style={{ paddingLeft: 10 }}
                    onPress={() => {
                      getDataReply(comment);
                      changeReply(true);
                    }}
                  >
                    <FooterText textAlign="left" color={THEME.COLORS.TEXT_MAIN}>
                      Responder
                    </FooterText>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </View>
          {comment.replies && (
            <FlatList
              data={comment.replies}
              keyExtractor={(item, index) => index}
              renderItem={({ item }) => (
                <ReplyItem
                  reply={item}
                  handleDeleteReply={
                    idPost ? handleDeleteReply : handleDeleteReplyCard
                  }
                  comment={comment}
                  user={login}
                />
              )}
            />
          )}
        </View>
      </ViewComment>
    )
  );
};

export default CommentItem;
