import styled from 'styled-components/native'
import THEME from '../../config/theme';
import { RFValue } from "react-native-responsive-fontsize";


export const Avatar = styled.Image`
width: ${({ width }) => width};
height: 100%;
`;

export const Row = styled.View`
  flex-direction: row;
  height: 3rem;
  align-items: center;
  height: ${({ height }) => (height ? height : '')}rem;
`;

export const ViewLogo = styled.View`
  align-items: center;
  height: 3rem;
  padding: 0.2rem;
`;

export const Content = styled.View`
  align-items: center;
  justify-content: center;
  flex-direction: row; 
  padding: 0.5rem 0rem;
`;

export const IconButton = styled.TouchableOpacity`
  align-items: center;
  flex-direction: row;
  margin-horizontal: 0.5rem;
`;